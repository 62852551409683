class Topograph extends ShaderLayer
	webgl : true
	preload : () ->
		@vert = DEFAULT_VERTEX_SHADER
		@draw_frag = loadStrings "src/topo_draw.frag"
		@height_frag = loadStrings "src/topo.frag"
	reset : () ->
		# @images[0].clear()
		# @images[1].clear()
		@shaders[0].setUniform 'topo_clear', 1
		@shaders[1].setUniform 'topo_clear', 1
		@render()
		@render()
	topo_dir : -1.0
	flip_direction : () ->
		@topo_dir = @topo_dir *  -1
		# console.log @topo_dir
		@shaders[0].setUniform 'topo_dir', @topo_dir
		@shaders[1].setUniform 'topo_dir', @topo_dir
	setup : () ->
		console.log "setting map"

		@pos = createVector 0.5, 0.5
		@direction = createVector 0, 1
		# @image.shader @shader
		# @image.noStroke()
		@res = 128
		@map = new Uint8Array @res * @res * 4

		@images = [
			createGraphics @res, @res, WEBGL
			createGraphics @res, @res, WEBGL
		]

		@images[0].setAttributes "alpha", true
		@images[1].setAttributes "alpha", true

		@images[0].background(128,0,0)
		@images[1].background(128,0,0)

		@vert = @vert.join "\n"
		@draw_frag = @draw_frag.join "\n"
		@height_frag = @height_frag.join "\n"

		@shaders = @images.map (i) => i.createShader(@vert, @draw_frag)

		@shader = @image.createShader @vert, @height_frag

		@images[0].shader @shaders[0]
		@images[1].shader @shaders[1]

		# @image.shader @shader

		@shader.setUniform "slice", 0.0
		@shader.setUniform 'slice_pos', 1.0
		# @shader.setUniform 'color_a', 0xffaa00


		# @shader.setUniform 'color_b', [0,0,0,0]
		@shaders[0].setUniform 'topo_dir', @topo_dir
		@shaders[1].setUniform 'topo_dir', @topo_dir
		@shaders[0].setUniform 'tex', @images[1]
		@shaders[1].setUniform 'tex', @images[0]
		@shaders[0].setUniform 'resolution', @res
		@shaders[1].setUniform 'resolution', @res

		@color_a = color(Colors.blue)._array.slice(0,3)
		@color_b = color(Colors.yellow)._array.slice(0.3)
		@color_c = color(Colors.white)._array.slice(0.3)

	get_slope : (p) ->
		if @map?
			px = floor(p.x * @res)
			py = floor(p.y * @res)
			pi = (px + py * @res) * 4
			v = createVector ((@map[pi + 1] - 128 )/255) * 2.0, ((@map[pi + 2] - 128 )/255) * 2.0
			v
		else
			createVector 0, 0
	focus : () ->
		@set_draw_layer(0.0)
		@image.shader @shader

	defocus : () ->
		@image.resetShader()
	draw : (delta = 0, time = 0, external = false) ->
		# @image.background Colors.white
		
		@shader.setUniform "time", time
		@image.fill 255
		@image.noStroke()
		@shader.setUniform 'color_a', @color_a
		@shader.setUniform 'color_b', @color_b
		@shader.setUniform 'color_c', @color_c
		@image.rect @size * - 0.5,@size * - 0.5,@size, @size


	render : () ->
		@images[@current].clear()
		@images[@current].noStroke()

		@images[@current].rect @res * -0.5,@res * -0.5, @res, @res
		# @images[@current].quad(-1, -1, 1, -1, 1, 1, -1, 1)
		@flip()
		@shader.setUniform "tex", @images[@current]

	blend : (delta) ->

	get_height : (pos) ->
		p = @get_pixel pos
		if p?
			@unipolar_byte p
		else
			-1.0

	start : () ->
		gl = @images[@current].canvas.getContext("webgl")
		gl.readPixels(0, 0, @res, @res, gl.RGBA, gl.UNSIGNED_BYTE, @map)
	press : () ->
		@start()
	tap : (p) ->
		@pos.set p
	set_draw_layer : (l) ->
		@shader.setUniform "merged", 0
		@shaders[0].setUniform "layer", l
		@shaders[1].setUniform "layer", l
		@shader.setUniform "layer", l
	panend : (p) ->
		@flip_direction()		
	pan : (p, delta) ->
		delta.normalize()
		@shaders[@current].setUniform "topo_clear", 0
		@shaders[@current].setUniform "x", p.x
		@shaders[@current].setUniform "y", p.y
		@shaders[@current].setUniform "delta", [delta.x, delta.y]
		@render()

